import {Injectable} from '@angular/core';
import {ApiService, TokenService} from 'app/core/services';
import {map} from 'rxjs/operators';

import {Booklet, Client} from "app/pages/booklet/interface";

@Injectable({providedIn: 'root'})

export class BookletService {
  constructor(private apiService: ApiService) {
  }

  async saveOffer(params: any): Promise<any> {
    if (params.id) {
      return this.editOffer(params);
    }
    return this.createOffer(params);
  }

  async createOffer(params: any): Promise<any> {
    const clubId = await TokenService.getClubId();
    const url = `create-promo.php?clubId=${clubId.value}`;
    return this.apiCall(url, params);
  }

  async editOffer(params: any): Promise<any> {
    const clubId = await TokenService.getClubId();
    const url = `update-promo.php?clubId=${clubId.value}`;
    return this.apiCall(url, params);
  }

  async getOffers( page= 1,limit?: number ): Promise<Booklet[]> {
    const clubId = await TokenService.getClubId();
    let url = `get-promo-booklet.php?clubId=${clubId.value}&page=${page}&filter=DESC`;
    if (limit) {
      url += '&limit=' + limit;
    }
    return this.apiService.get(url).pipe(
      map((res) => res.promos)
    ).toPromise();
  }

  async getClient(): Promise<Client> {
    const clubId = await TokenService.getClubId();
    const params = {
      solutionId: clubId.value
    };
    return this.apiService.post('get-client.php', ApiService.createFormData(params)).pipe(
      map(res => res['client']))
      .toPromise();
  }

  async sendToClub(params: any): Promise<any> {
    const url ='send-to-club.php';
    return this.apiService.post(url, ApiService.createFormData(params)).toPromise();
  }

  async getSinglePromo(id: string): Promise<Booklet> {
    const url = `get-single-promo.php?promoId=${id}`;
    return this.apiService.get(url).pipe(
      map(res => res['promo'])
    ).toPromise();
  }

  deleteOffer(id: string): Promise<any> {
    return this.apiService.del(`delete-promo.php?PromoId=${id}`).toPromise();
  }

  private async apiCall(url, params) {
    return this.apiService.post(url, ApiService.createFormData(params)).toPromise();
  }
}
