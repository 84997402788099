import {NyhedTilbud} from 'app/pages/nyhed-tilbud/interface';

export const INITIAL_FORM_DATA: NyhedTilbud = {
  id:'',
  Offers: [{
    Title: '',
    LongDescription: '',
    InAppParameters: {
      price: ''
    },
    ImageUrl: {
      largeImage: '',
      thumbnail: ''
    }
  }],
  Color: '',
  sms: {
    smsText: '',
    smsPublishDate: ''
  },
  facebook: {
    fbText: '',
    fbPublishDate: ''
  },
  CoopOriginId: '',
  Shared: {
    facebook: false,
    sms: false
  },
  ShareMessage: false,
  booklet: 0,
  isSet: false
};
