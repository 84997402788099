export const Booklet_Validation_Messages = {
  largeImage: {
    required: '_please_upload_image_'
  },
  smsText: {
    required: '_sms_message_is_required_',
    emoji: '_field_cannot_contain_emoji_characters_'
  },
  smsPublishDate: {
    invalidDate: '_past_date_selected_'
  },
  fbPublishDate: {
    invalidDate: '_past_date_selected_'
  }
};
