import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {INITIAL_FORM_DATA} from "app/pages/booklet/data";
import {Booklet} from "app/pages/booklet/interface";

@Injectable({
  providedIn: 'root'
})
export class BookletStateService {
  formState = new BehaviorSubject<Booklet>(INITIAL_FORM_DATA);
  constructor() {
  }

  setFormState(state: Booklet) {
    this.formState.next(state);
  }
}
