import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {NyhedTilbud} from 'app/pages/nyhed-tilbud/interface';
import {Observable} from 'rxjs';

import {NyhedTilbudService} from './nyhed-tilbud.service';

@Injectable({
  providedIn: 'root'
})
export class NyhedTilbudResolverService implements Resolve<NyhedTilbud[]> {
  constructor(private nyhedTilbudService: NyhedTilbudService) {
  }
  // eslint-disable-next-line max-len
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NyhedTilbud[]> | Promise<NyhedTilbud[]> | NyhedTilbud[] | any {
    const id = route.params.id;
    const observablePromise = new Promise((resolve, reject) => {
      resolve(this.nyhedTilbudService.getSinglePromo(id));
    });
    return observablePromise;
  }


}
