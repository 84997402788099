import {Booklet} from "../interface";

export const INITIAL_FORM_DATA: Booklet = {
  id:'',
  Offers: [{
    Title: '',
    LongDescription: '',
    InAppParameters: {
      price: ''
    },
    ImageUrl: {
      largeImage: '',
      thumbnail: ''
    }
  }],
  Color: '',
  sms: {
    smsText: '',
    smsPublishDate: ''
  },
  facebook: {
    fbText: '',
    fbPublishDate: ''
  },
  CoopOriginId: '',
  Shared: {
    facebook: false,
    sms: false
  },
  ShareMessage: false,
  booklet: 1,
  isSet: false
};
