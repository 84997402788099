import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {Booklet} from "app/pages/booklet/interface";

import {BookletService} from "./booklet.service";

@Injectable({
  providedIn: 'root'
})
export class BookletResolverService implements Resolve<Booklet[]> {
  constructor(private bookletService: BookletService) {
  }
  // eslint-disable-next-line max-len
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Booklet[]> | Promise<Booklet[]> | Booklet[] | any {
    const id = route.params.id;
    const observablePromise = new Promise((resolve, reject) => {
      resolve(this.bookletService.getSinglePromo(id));
    });
    return observablePromise;
  }


}
