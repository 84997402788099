import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AlertController, ViewWillEnter } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { TokenService } from 'app/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
})
export class TabsPage implements OnInit, OnDestroy, ViewWillEnter {
  static tabVisible = new BehaviorSubject<boolean>(true);
  isTabVisible = true;
  private subscriptionState = true;

  private isPremiumUser = false;

  constructor(
    private alertCtrl: AlertController,
    private _translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    TabsPage.tabVisible.pipe(
      takeWhile(() => this.subscriptionState)
    ).subscribe(
      (visible) => {
        this.isTabVisible = visible;
      },
      err => console.log('tabs error', err)
    );
  }

  async ionViewWillEnter() {
    this.isPremiumUser = await TokenService.isPremiumUser();
  }

  ngOnDestroy() {
    this.subscriptionState = false;
  }

  async checkPremium() {
    if (!this.isPremiumUser) {
      const alertMsg = await this.alertCtrl.create({
        mode: 'ios',
        cssClass: 'alert-dialog',
        header: this._translate.instant('_jatak_premium_feature_'),
        message: this._translate.instant('_contact_us_for_function_'),
        buttons: [
          {
            text: this._translate.instant('_back_'),
            role: 'cancel',
            cssClass: 'alert-cancel',
          },
        ],
      });
      await alertMsg.present();
      return;
    }
  }

  async help() {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      message: this._translate.instant('_contact_us_message_'),
      buttons: [
        {
          text: this._translate.instant('_back_'),
          role: 'cancel'
        },
        {
          text: this._translate.instant('_contact_us_'),
          handler: () => this._callCoop(alert)
        },
      ]
    });
    await alert.present();
  }

  private _callCoop(alert) {
    window.open('tel:+4522145456');
    alert.dismiss();
  }

}
