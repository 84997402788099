import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";

import {ApiService, TokenService} from "app/core/services";
import { Client } from "app/pages/sms-klub/interface";

@Injectable({providedIn: 'root'})
export class SmsClubService {

  constructor(private apiService: ApiService) {
  }

  async getClient(): Promise<Client> {
    const clubId = await TokenService.getClubId();
    const params = {
      solutionId: clubId.value
    };
    return this.apiService.post('get-client.php', ApiService.createFormData(params)).pipe(
      map(res => res['client']))
      .toPromise();
  }

  editStandardMsg(message: string, id: string) {
    const params = {
      solutionId: id,
      standardText: message
    };
    return this.apiService.put('edit-standard-text.php', ApiService.createFormData(params)).toPromise();
  }
}
