import {Injectable} from "@angular/core";

import {ApiService, TokenService} from "app/core/services";
import {Sms, SMSItem} from "app/pages/sms/interface";
import { BehaviorSubject, Observable } from "rxjs";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class SmsService {

  constructor(private apiService: ApiService) {
  }

  async getSmsList(from: string, to: string, page = 1, limit?: number): Promise<Sms[]> {
    const clubId = await TokenService.getClubId();
    let url = `get-sms-list.php?clubId=${clubId.value}&from=${from}&to=${to}&page=${page}&filter=DESC`;
    if (limit) {
      url += '&limit=' + limit;
    }
    return this.apiService.get(url).pipe(
      map((res) => res.promos)
    ).toPromise();
  }

  async getPastFutureSmsList(when: string): Promise<SMSItem[]> {
    const clubId = await TokenService.getClubId();
    let url = `get-sms-list.php?clubId=${clubId.value}&for=${when}&filter=DESC`;
    return this.apiService.get(url).pipe(
      map((res) => res.promos)
    ).toPromise();
  }

  async getSingleSms(id: string): Promise<Sms> {
    return this.apiService.get(`get-single-sms.php?id=${id}`).pipe(
      map((res) => res.sms)
    ).toPromise();
  }

  async saveSms(params: any, isUsingTemplate = false): Promise<any> {
    if (!isUsingTemplate && params.id) {
      return this.editSms(params);
    }
    return this.createSms(params);
  }

  async createSms(params: any): Promise<any> {
    const url = 'send-sms.php';
    return this.apiCall(url, params);
  }

  async editSms(params: any): Promise<any> {
    const url = 'update-sms.php';
    return this.apiCall(url, params);
  }

  async deleteSms(id: string): Promise<any> {
    return this.apiService.del(`delete-sms.php?id=${id}`).toPromise();
  }

  private async apiCall(url, params) {
    return this.apiService.post(url, ApiService.createFormData(params)).toPromise();
  }
}
