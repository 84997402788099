import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {NyhedTilbud} from 'app/pages/nyhed-tilbud/interface';
import {INITIAL_FORM_DATA} from 'app/pages/nyhed-tilbud/data';

@Injectable({
  providedIn: 'root'
})
export class NyhedTilbudStateService {
  formState = new BehaviorSubject<NyhedTilbud>(INITIAL_FORM_DATA);
  constructor() {
  }

  setFormState(state: NyhedTilbud) {
    this.formState.next(state);
  }
}
